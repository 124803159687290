<template>
  <div id="agenda-manage-page">
    <section class="for-rt">
      <div class="container">
        <div class="room-title">Agenda List</div>
      </div>
    </section>

    <section class="show-item">
      <div class="container">
        <div class="filter-row">
          <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
          <div class="button-filter">
            <router-link to="/agenda/manage/create" class="btn btn-orange w-100">Add Agenda</router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Show Entries</label>
              <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Search</label>
              <input type="text" name="search" id="search" class="form-control" placeholder="Search..." v-model="filter">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="room-items pt-0">
      <div class="container">
        <!-- Table -->
        <b-table
          outlined
          ref="table"
          :items="fetchAgenda"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :empty-text="'There are no records to show'"
          :empty-filtered-text="'There are no records matching your request'"
          class="table-manage"
        >
          <!-- Aciton -->
          <template v-slot:cell(action)="data">
            <div class="box-btn-action">
              <button type="button" class="btn btn-action mx-1" @click="detailAgenda(data.item.slug)">
                <span class="icon-ico-eye"></span>
              </button>
              <button type="button" class="btn btn-action mx-1" @click="$router.push(`/agenda/manage/edit/${data.item.id}`)">
                <span class="icon-ico-pencil"></span>
              </button>
              <button type="button" class="btn btn-action mx-1 for-delete" @click.prevent="deleteId = data.item.id">
                <span class="icon-ico-delete"></span>
              </button>
            </div>
          </template>
        </b-table>
        <div class="filter-row">
          <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
          <div class="button-filter">
            <ul class="pagination pagination-rounded mb-0">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- popup confirm delete -->
    <div class="popup-overlay" :class="{ show: deleteId}">
      <div class="inner-delete" :class="{ popup: deleteId}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want to delete this agenda ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-delete" @click="deleteId = null">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-delete-pink w-100" id="submit-delete" @click="remove">Delete</button>
          </div>
        </div>
      </div>
    </div>

    <!-- popup detail agenda -->
    <div class="popup-agenda" :class="{ show: Object.keys(agenda).length > 0 }">
      <div class="popup-agenda-inner">
        <div class="agenda-close">
          <button class="btn btn-close for-agenda" @click="agenda = {}"></button>
        </div>
        <div class="mb24">
          <table class="table borderless" cellspacing="2">
            <tr>
              <td class="point">Title</td>
              <td>:</td>
              <td class="point-desc">{{ agenda.title }}</td>
            </tr>
            <tr>
              <td class="point">Start Date</td>
              <td>:</td>
              <td class="point-desc">{{ agenda.start_date }}</td>
            </tr>
            <tr>
              <td class="point">End Date</td>
              <td>:</td>
              <td class="point-desc">{{ agenda.end_date }}</td>
            </tr>
            <tr>
              <td class="point">Description</td>
              <td>:</td>
              <td class="point-desc" v-html="agenda.description"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "AgendaManage",
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "agendas.created_at",
      sortDesc: false,
      fields: [
        { key: "title", label: "TITLE", sortable: true },
        { key: "case", label: "CASE", sortable: true },
        { key: "start_date", label: "DATE", sortable: true },
        'ACTION'
      ],
      deleteId: null,
      agenda: {}
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  methods: {
    async fetchAgenda(ctx) {
      console.log(ctx)
      const promise = this.$axios.get(`/master/agenda`, {
        params: {
          page: ctx.currentPage,
          per_page: ctx.perPage,
          search: ctx.filter,
          sortBy: (ctx.sortBy == 'case')? 'subject_topic' : ctx.sortBy,
          sortType: (ctx.sortDesc)? 'asc' : 'desc'
        }
      })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        const items = response.data.data.data
        this.totalRows = response.data.data.meta.total
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    detailAgenda(slug) {
      this.$axios.get(`/master/agenda/${slug}`)
        .then(response => {
          this.agenda = response.data.data
        })
    },
    remove() {
      let param = new FormData();
      param.append('_method', 'delete');
      param.append('id', this.deleteId);
      this.$axios.post('/master/agenda', param)
        .then(response => {
          console.log(response)
          Swal.fire("Success", response.data.message, "success");
          this.$refs.table.refresh();
          this.deleteId = null
        })
    },
  }
}
</script>

<style scoped>
td {
  padding: 10px;
}
.point {
  width: 20%;
  font-size: 14px;
  color: #7B7B7B;
}
.point-desc {
  width: 80%;
  font-weight: 600;
  font-size: 14px;
  color: #434343;
}
.black {
  color: black !important;
}
</style>