import { render, staticRenderFns } from "./AgendaManage.vue?vue&type=template&id=435995da&scoped=true&"
import script from "./AgendaManage.vue?vue&type=script&lang=js&"
export * from "./AgendaManage.vue?vue&type=script&lang=js&"
import style0 from "./AgendaManage.vue?vue&type=style&index=0&id=435995da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435995da",
  null
  
)

export default component.exports